<template>
  <Waterfall
    :list="list"
    :gutter="5"
    :breakpoints="breakpoints"
    backgroundColor="#f1f1f1"
    :animationDelay="0"
    :animationDuration="0"
    :lazyload="false"
  >
    <template #item="{ item, url }">
      <div class="card div-bg" @click="to_product_details(item.id)">
        <LazyImg class="card_img" :url="url" />
        <div class="bg"></div>
      </div>
    </template>
  </Waterfall>
</template>

<script>
import { LazyImg, Waterfall } from "vue-waterfall-plugin";
import "vue-waterfall-plugin/dist/style.css";
export default {
  components: { LazyImg, Waterfall },
  data() {
    return {
      list: [
        {
          id: 1,
          src: require("./img/1.png"),
        },
        {
          id: 2,
          src: require("./img/2.png"),
        },
        {
          id: 3,
          src: require("./img/3.png"),
        },
        {
          id: 4,
          src: require("./img/4.png"),
        },
        {
          id: 5,
          src: require("./img/5.png"),
        },
        {
          id: 6,
          src: require("./img/6.png"),
        },
      ],
      breakpoints: {
        1200: {
          //当屏幕宽度小于等于1200
          rowPerView: 4,
        },
        800: {
          //当屏幕宽度小于等于800
          rowPerView: 3,
        },
        500: {
          //当屏幕宽度小于等于500
          rowPerView: 2,
        },
      },
    };
  },
  methods: {
    to_product_details(id) {
      this.$router.push({ path: "/product-details", query: { id: id } });
    },
  },
};
</script>